/* used to import google fontss font */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');
/* root properties decleration */
:root{
    --green:#27ae60;
    --black:#192a56;
    --light-color:#666;
    --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
}
/* universal properties */
*{
    font-family: 'Nunito', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none; border:none;
    text-transform: capitalize;
    transition: all .2s linear;
}
/* properties given to the html tag */
html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 5.5rem;
    scroll-behavior: smooth;
}
/* section pading */
section{
    padding:2rem 9%;
}
/* even sections color giving */
section:nth-child(even){
    background:#eee;
}
/* sub headings formating in all sections */
.sub-heading{
    text-align: center;
    color:var(--green);
    font-size: 2rem;
    padding-top: 1rem;
}
/* bigger sub headings formating in all sections */
.heading{
    text-align: center;
    color:var(--black);
    font-size: 3rem;
    padding-bottom: 2rem;
    text-transform: uppercase; /*text change to uppercase*/
}
/* button formating univarsal to entire page */
.btn{
    margin-top: 1rem;
    display: inline-block;
    font-size: 1.7rem;
    color:#fff;
    background: var(--black);
    border-radius: .5rem;
    cursor: pointer;
    padding:.8rem 3rem;
}
/* btn hover porperty */
.btn:hover{
    background: var(--green);
    letter-spacing: .1rem;
}
/* header section formating */
header{
    position: fixed; /*Header/nav will stay in place while scrolling*/
    top:0; left: 0; right:0; /*used for positioning*/
    background: #fff;
    padding:1rem 7%;
    display: flex; /*setting elements horizontolly*/
    align-items: center;
    justify-content: space-between; /*gives equal space inbetween elements*/
    z-index: 1000; /*declears stack positioning/nav will stay at the top of the stack*/
    box-shadow: var(--box-shadow);
}
/* logo formating */
header .logo{
    color:var(--black);
    font-size: 2.5rem;
    font-weight: bolder;
}
/* logo image formating */
header .logo i{
    color:var(--green);
}
/* nav elements formatting */
/* .header-menu{
    font-size: 1.7rem;
    border-radius: 5px;
    gap: 4rem; 
    color:var(--light-color);
    display: flex;
}
.header-menu a.active,
.header-menu a:hover{
    color:#fff;
    background: var(--green);
} */
header .navbar a{
    font-size: 1.7rem;
    border-radius: .5rem;
    padding:.5rem 4rem; 
    color:var(--light-color);
} 

/* active and hover color change property */
header .navbar a.active,
header .navbar a:hover{
    color:#fff;
    background: var(--green);
}
/* header icons formatting */
header .icons i{
    cursor: pointer;
    margin-left: .5rem;
    height:4.5rem;
    line-height: 4.5rem;
    width:4.5rem;
    text-align: center;
    font-size: 1.7rem;
    color:var(--black);
    border-radius: 50%;
    background: #eee;
}
/* icons hover formatting */
header .icons i:hover{
    color:#fff;
    background: var(--green);
    transform: rotate(360deg); /*rotates the icon 360deg*/
}
/* hides the menu bar at full width */
header .icons #menu-bars{
    display: none;
}
/* header slider */
.home .home-slider .slide{
    display: flex;
    align-items: center;
    gap:2rem; 
    padding-top: 9rem;
    padding-left: 10%;
}
/* formats and positions the content of header in order */
.home .home-slider .slide .content{
    flex:1 1 45rem;
}
/* image positioning */
.home .home-slider .slide .image{
    flex:1 1 45rem;
}
/* image width */
.home .home-slider .slide .image img{
    width: 85%;
padding-top: 5rem;
padding-bottom: 5rem;
}
/* subhead */
.home .home-slider .slide .content span{
    color:var(--green);
    font-size: 2.5rem;
}
/* heading formating */
.home .home-slider .slide .content h3{
    color:var(--black);
    font-size: 7rem;
}
/* para formating in header */
.home .home-slider .slide .content p{
    color:var(--light-color);
    font-size: 2.2rem;
    padding:.5rem 0;
    line-height: 1.5;
}
/* swipe button color */
.swiper-pagination-bullet-active{
    background:var(--green);
}
/* Swiper header css ends here */

/* dishes section  */
.dishes .box-container{
    display: grid; /*converting html element into a grid*/
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr)); /*repeating columns vertically and giving the width n height*/
    gap:1.5rem; /*gap b/w grid elements*/
}
/* formatting boxes of dishes grid */
.dishes .box-container .box{
    padding:2.5rem;
    background:#fff;
    border-radius: .5rem;
    border:.1rem solid rgba(0,0,0,.2);
    box-shadow: var(--box-shadow);
    position: relative; /*allows the elemnt to move when left right property is set*/
    overflow: hidden; /*hides overflown content*/
    text-align: center;
}

/* dishes images format */
.dishes .box-container .box img{
    height:17rem;
    margin:1rem 0;
}
/* dishes heading format */
.dishes .box-container .box h3{
    color:var(--black);
    font-size: 2.5rem;
}
/* star rating spacing*/
.dishes .box-container .box .stars{
    padding:1rem 0;
}
/*  star rating formatting*/
.dishes .box-container .box .stars i{
    font-size: 1.7rem;
    color:var(--green);
}
/* dishes price formatting */
.dishes .box-container .box span{
    color:var(--green);
    font-weight: bolder;
    margin-right: 1rem;
    font-size: 2.5rem;
}
/* DISHES SECTION ENDS HERE */

/* ABOUT SECTION FORMATING */
.about .row{
    display: flex; /*setting elements horizontolly*/
    flex-wrap: wrap; /*wrapping the content if it overflows*/
    gap:1.5rem; /*gap b/w content*/
    align-items: center;
}
/* image positioning */
.about .row .image{
    flex:1 1 45rem;
}
/* image sizing */
.about .row .image img{ 
    width: 100%;
}
/* positioning */
.about .row .content{
    flex:1 1 45rem;
}
/* heading formating */
.about .row .content h3{
    color:var(--black);
    font-size: 4rem;
    padding:.5rem 0;
}
/* about para formatting */
.about .row .content p{
    color:var(--light-color);
    font-size: 1.5rem;
    padding:.5rem 0;
    line-height: 2; /*line space b/w lines*/
}
/* icons container positioning */
.about .row .content .icons-container{
    display: flex;
    gap:1rem; /*gap b/w icons*/
    flex-wrap: wrap;
    padding:1rem 0;
    margin-top: .5rem;
}
/* inside icon container formating */
.about .row .content .icons-container .icons{
    background:#eee;
    border-radius: .5rem;
    border:.1rem solid rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1rem;
    flex:1 1 17rem;
    padding:1.5rem 1rem;
}
/* icon formating only */
.about .row .content .icons-container .icons i{
    font-size: 2.5rem;
    color:var(--green);
}
/* icon desc properties */
.about .row .content .icons-container .icons span{
    font-size: 1.5rem;
    color:var(--black);
}
/* ABOUT SECTION ENDS HERE */

/* MENU SECTION FORMATING */
.menu .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap:1.5rem;
}

.menu .box-container .box{
    background: #fff;
    border:.1rem solid rgba(0,0,0,.2);
    border-radius: .5rem;
    box-shadow: var(--box-shadow);    
}
/* giving dimension here */
.menu .box-container .box .image{
    height: 25rem;
    width: 100%;
    padding:1.5rem;
    overflow: hidden;
    position: relative;
}

.menu .box-container .box .image img{
    height: 100%;
    width: 100%;
    border-radius: .5rem;
    object-fit: cover; /*makes the image cover the dimension it was given*/
}

.menu .box-container .box .content{
    padding:2rem;
    padding-top: 0;
}

.menu .box-container .box .content .stars{
    padding-bottom: 1rem;
}

.menu .box-container .box .content .stars i{
   font-size: 1.7rem;
   color:var(--green);
}

.menu .box-container .box .content h3{
    color:var(--black);
    font-size: 2.5rem;
}

.menu .box-container .box .content p{
    color:var(--light-color);
    font-size: 1.6rem;
    padding:.5rem 0;
    line-height: 1.5;
}

.menu .box-container .box .content .price{
    color:var(--green);
    margin-left: 1rem;
    font-size: 2.5rem;
}
/* MENU SECTION ENDS HERE */

/* REVIEW SECTION CSS */
.review .slide{
    padding:2rem;
    box-shadow:var(--box-shadow);
    border:.1rem solid rgba(0,0,0,.2);
    border-radius: .5rem;
    position: relative;    
}
/* quotation mark formating */
.review .slide .fa-quote-right{
    position: absolute; /*stay in place*/
    top:2rem; right:2rem;
    font-size: 6rem;
    color:#ccc;
}
/* each user review formating */
.review .slide .user{
    display: flex;
    gap:1.5rem;
    align-items: center;
    padding-bottom: 1.5rem;
}
/* image formating of user */
.review .slide .user img{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit: cover;
}

.review .slide .user h3{
    color:var(--black);
    font-size: 2rem;
    padding-bottom: .5rem;
}
/* stars formating */
.review .slide .user i{
    font-size: 1.3rem;
    color:var(--green);
}

.review .slide p{
    font-size: 1.5rem;
    line-height: 1.8;
    color:var(--light-color);
}
/* REVIEW SECTION ENDS HERE */


/* ORDER SECTION CSS */
.order form{
    max-width:90rem;
    border-radius: .5rem;
    box-shadow: var(--box-shadow);
    border:.1rem solid rgba(0,0,0,.2);
    background:#fff;
    padding:1.5rem;
    margin:0 auto;
}

.order form .inputBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;  /*adding equal space in b/w elements*/
}

.order form .inputBox .input{
    width:49%;
}
/* input headings */
.order form .inputBox .input span{
    display:block; 
    padding:.5rem 0;
    font-size: 1.5rem;
    color:var(--light-color);
}

.order form .inputBox .input input,
.order form .inputBox .input textarea{
    background:#eee;
    border-radius: .5rem;
    padding:1rem;
    font-size: 1.6rem;
    color:var(--black);
    text-transform: none; /*eliminates the upper given upper case property*/
    margin-bottom: 1rem;
    width:100%;
}
/* when input section is clicked */
.order form .inputBox .input input:focus,
.order form .inputBox .input textarea:focus{
    border:.1rem solid var(--green);
}

.order form .inputBox .input textarea{
    height:20rem;
    resize: none;
}

.order form .btn{
    margin-top: 0;
}
/* ORDER SECTION ENDS HERE */


/* FOOTER CSS */
.footer .box-container{ /*creating a grid to seperate elements*/
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap:1.5rem;
}

.footer .box-container .box h3{
    padding:.5rem 0;
    font-size: 2.5rem;
    color:var(--black);
}

.footer .box-container .box a{
    display: block;
    padding:.5rem 0;
    font-size: 1.5rem;
    color:var(--light-color);
}

.footer .box-container .box a:hover{
    color:var(--green);
    text-decoration: underline;
}
/* FOOTER ENDS HERE */


/* PAGE LOADER  */
.loader-container{
    position: fixed;
    top:0; left:0;
    height:100%; 
    width:100%;
    z-index: 10000; /*stack position determining*/
    background:#fff;
    display: flex;
    align-items: center;
    justify-content: center; /*center positioning*/
}

.loader-container img{
    width:35rem;
}

.loader-container.fade-out{
    top:-110%;
    opacity:0;
}





















/* 

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var((--appColor));
        padding: 2rem;
    }
} */





/* media queries  */
/* slight changes in basic formatting when width changes */
@media (max-width:991px){

    html{
        font-size: 55%;
    }
    
    header{
        padding:1rem 2rem;
    }

    section{
        padding:2rem;
    }


}
/* deals with the menu bar dropdown*/
@media (max-width:768px){ /* at this width menu bar will show*/

    header .icons #menu-bars{
        display: inline-block; /*allow the item to displau horizontolly*/
    }

    header .navbar{
        position: absolute; /*sets the positon of the navbar*/
        top:100%; left:0; right:0;
        background:#fff;
        border-top: .1rem solid rgba(0,0,0,.2);
        border-bottom: .1rem solid rgba(0,0,0,.2);
        padding:1rem;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);  /*deetrmines what parts of the element should be shown*/
/*here used to hide the nav elements*/    }

    header .navbar.active{
        /* show nav elements using j query */
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
/* sets the nav element in dropdown */
    header .navbar a{
        display: block;
        padding:1.5rem;
        margin:1rem;
        font-size: 2rem;
        background:#eee;
    }
   
/* changes h3 font size of slider */
    .home .home-slider .slide .content h3{
        font-size: 5rem;
    }

}
/* at 450 width formats the order now and dishes boxes */
@media (max-width:450px){

    html{
        font-size: 50%;
    }

    .dishes .box-container .box img{
        height:auto;
        width: 100%;
    }

    .order form .inputBox .input{
        width:100%;
    }

}

